@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Investment Classes */
@layer base {

    @font-face {
        font-family: 'machomodular';
        src: url('../public/fonts/machomodular-bold-webfont.woff2') format('woff2'),
             url('../public/fonts/machomodular-bold-webfont.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'machomodular';
        src: url('../public/fonts/machomodular-bolditalic-webfont.woff2') format('woff2'),
             url('../public/fonts/machomodular-bolditalic-webfont.woff') format('woff');
        font-weight: bold;
        font-style: italic;
    }
    @font-face {
        font-family: 'machomodular';
        src: url('../public/fonts/machomodular-regular-webfont.woff2') format('woff2'),
             url('../public/fonts/machomodular-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'machomodular';
        src: url('../public/fonts/machomodular-regularitalic-webfont.woff2') format('woff2'),
             url('../public/fonts/machomodular-regularitalic-webfont.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }

    :root {
        font-size: 18px;
        @apply font-copy bg-bb font-normal text-black;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply text-bb font-heading font-bold capitalize;
    }
    h1 {
        @apply text-h1;
    }
    h2 {
        @apply text-h2;
    }
    h3 {
        @apply text-h3;
    }
    h4 {
        @apply text-copy;
    }
    h5 {
        @apply text-copy;
    }
    h6 {
        @apply text-copy;
    }

    hr {
        @apply border-grey;
    }

    a {
        @apply text-cc underline;
    }
}

@layer components {
    .sol-asx-shareprice .sol-asx-shareprice-price .yourir-dollars {
        display: flex;
        align-items: top;
    }
    .sol-asx-shareprice .sol-asx-shareprice-price .yourir-integer-part,
    .sol-asx-shareprice .sol-asx-shareprice-price .yourir-decimal-part {
        font-size: 4.0rem;
        line-height: 4.0rem;
    }
    .sol-asx-shareprice .sol-asx-shareprice-price .yourir-currency-symbol {
        font-size: 2.0rem;
        line-height: 2.0rem;
    }

    /* Timeline */
    .sol-timeline-dot {
        display: block;
        height: 30px;
        position: relative;
        width: 20px;
    }
    .sol-timeline-dot:before,
    .sol-timeline-dot:after {
        display: block;
        content: '';
        border-radius: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
    }
    .sol-timeline-dot:before {
        background: theme('colors.bb');
        height: 20px;
        margin-left: -10px;
        margin-top: -10px;
        width: 20px;
    }
    .sol-timeline-dot:after {
        background: theme('colors.white');
        height: 10px;
        margin-left: -5px;
        margin-top: -5px;
        width: 10px;
    }
    .sol-timeline-line {
        position: relative;
    }
    .sol-timeline-line:after {
        position: absolute;
        width: 5px;
        content: '';
        left: 50%;
        margin-left: -2.5px;
        top: 0;
        bottom: 0;
        background: theme('colors.grey-2');
    }

    /* Markdown content rules */
    .sol-markdown-content h1,
    .sol-markdown-content h2,
    .sol-markdown-content h3,
    .sol-markdown-content h4,
    .sol-markdown-content h5,
    .sol-markdown-content h6 {
        @apply text-bb mb-20 mt-40;
    }

    .sol-markdown-content p {
        @apply text-black mb-20;
    }

    /* .sol-markdown-content a {
        @apply no-underline hover:underline;
    } */
    .sol-markdown-content hr {
        @apply my-20 bg-grey h-[3px];
    }

    .sol-markdown-content ul {
        @apply list-disc
    }
    .sol-markdown-content ol[type="1"] {
        @apply list-decimal
    }
    .sol-markdown-content ol[type="a"] {
        @apply list-lower-alpha
    }
    .sol-markdown-content ul,
    .sol-markdown-content ol {
        @apply pl-40 mb-20
    }

    .sol-markdown-content a {
        word-break: break-word;
    }

    .sol-asx-announcement-detail-dark,
    .sol-asx-announcement-detail {
        position: relative;
    }

    .sol-asx-announcement-detail-dark:after {
        @apply bg-detail-arrow-dark;
    }
    .sol-asx-announcement-detail:after {
        @apply bg-detail-arrow;
    }

    .sol-asx-announcement-detail-dark:after,
    .sol-asx-announcement-detail:after {
        @apply bg-cover -rotate-90 transition-transform;
        content: '';
        height: 0.66rem;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        width: 1.0rem;
        margin-top: -0.5rem;
    }
    details[open] .sol-asx-announcement-detail-dark:after,
    details[open] .sol-asx-announcement-detail:after {
        @apply rotate-0;
    }
}

/* YourIR Component Styles */
#yourir-default-announcement {
    @apply z-modal;
    position: relative;
}